import { Typography } from '@mui/material';
import React from 'react';

const SectionTitle = ({ title }) => {
  return (
    <Typography style={{ textAlign: 'center', paddingBottom: 16 }} variant='h6'>
      {title}
    </Typography>
  );
};

export default SectionTitle;
