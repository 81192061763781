import { Container, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { getEventsByType, saveNewEvent } from 'apiRequests';
import { AddAndSaveButton } from 'assets/reusableItems/items';
import React, { useEffect, useState } from 'react';
import CreateNewEventDialog from './components/dialogs/CreateNewEventDialog';
import EventsTable from './components/EventsTable/EventsTable';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [type, setType] = useState('');
  const [openCreateNewEventDialog, setOpenCreateNewEventDialog] = useState(false);
  const handleTypeChange = event => {
    setType(event.target.value);
  };
  const handleSubmit = event => {
    saveNewEvent(event)
      .then(() => {
        alert('The event has been saved');
        setOpenCreateNewEventDialog(false);
      })
      .catch(err => {
        console.log(new Error(err));
        alert('There was a problem with event saving');
      });
  };
  useEffect(() => {
    if (type) {
      getEventsByType(type)
        .then(res => {
          setEvents(res.data);
        })
        .catch(err => {
          console.log(new Error(err));
        });
    }
  }, [toggle, type]);

  return (
    <Container style={{ padding: 32 }}>
      <Grid container>
        <Grid item>
          <AddAndSaveButton
            addOrSave='addOrder'
            onClickFunction={() => setOpenCreateNewEventDialog(true)}
            buttonName='Add new event'
          />
          <FormControl variant='outlined' size='small' fullWidth style={{ marginTop: 32 }}>
            <InputLabel>Type</InputLabel>
            <Select value={type} label='Type' onChange={handleTypeChange}>
              <MenuItem value={'stopsale'}>Stop Sale</MenuItem>
              <MenuItem value={'holiday'}>Holidays</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <EventsTable events={events} />
      <CreateNewEventDialog
        open={openCreateNewEventDialog}
        setOpen={setOpenCreateNewEventDialog}
        handleSubmit={handleSubmit}
      />
    </Container>
  );
};

export default Events;
