import React, { useState } from 'react';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tab,
  Tabs,
  Box,
  Container,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Quote, Reject, Order } from './Tabs';
import styled from 'styled-components';
import { Transition } from '../../../../assets';
/**
 * styles
 */
const MI = styled(MenuItem)``;
const StyledSelect = styled(Select)`
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-left: 3px;

  .MuiSelect-root {
    margin-top: 1px;
  }
`;
/**end styles */

const InputItem = ({ fieldName, handleInputChange }) => (
  <TextField
    autoComplete="none"
    fullWidth
    label={fieldName}
    onChange={handleInputChange}
  />
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const AddQuotation = ({ open, handleQuotationClose, ...rest }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [language, setLanguage] = useState('English');

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Container>
      <Dialog
        // fullScreen
        maxWidth="lg"
        open={open}
        onClose={handleQuotationClose}
        TransitionComponent={Transition}
      >
        <AppBar position="static">
          <Toolbar>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleQuotationClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" style={{ color: 'white' }}>
                  ADD QUOTATION IN
                </Typography>
                <StyledSelect
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <MI value={'English'}>ENGLISH</MI>
                  <MI value={'Russian'}>RUSSIAN</MI>
                </StyledSelect>
              </div>

              <Button autoFocus color="inherit" onClick={handleQuotationClose}>
                save
              </Button>
            </div>
          </Toolbar>
        </AppBar>

        {/* <Container style={{ marginTop: '64px', padding: '32px' }}> */}

        <AppBar
          position="static"
          color="default"
          style={{ maxWidth: '1280px' }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Quote" {...a11yProps(0)} />
            <Tab label="Order" {...a11yProps(1)} />
            <Tab label="Reject" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <Paper elevation={3}>
          <TabPanel value={value} index={0}>
            <Quote language={language === 'English' ? 'en' : 'ru'} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Order />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Reject />
          </TabPanel>
        </Paper>

        {/* </Container> */}
      </Dialog>
    </Container>
  );
};

export default AddQuotation;
