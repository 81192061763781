import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { getCarsImages } from 'apiRequests';
import Select from '@mui/material/Select';

const CarDetails = ({ handleCarChange, carImage, carModel, order, setOrder, isEdit }) => {
  const [cars, setCars] = useState([{ image: '', model: '' }]);
  const [selectedCar, setSelectedCar] = useState(isEdit ? { model: carModel, image: carImage } : cars[0]);
  useEffect(() => {
    const handleNewcars = cars => setCars(cars);
    getCarsImages()
      .then(cars => handleNewcars(cars.data))
      .catch(err => console.log('ERROR WHILE GETTING CARS', err));
  }, []);

  useEffect(() => {
    handleCarChange(selectedCar.image, selectedCar.model);
  }, [selectedCar]);

  return (
    <>
      <Typography style={{ textAlign: 'center', marginBottom: 8 }} variant='h6'>
        Car category
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Autocomplete
            fullWidth
            value={selectedCar}
            onChange={(e, newValue) => {
              if (newValue) setSelectedCar(newValue);
            }}
            options={cars}
            getOptionLabel={option => option.model}
            renderInput={params => <TextField {...params} label='car' variant='outlined' />}
          />
        </Grid>

        <Grid item>
          <img src={carImage} alt={carModel} width='70px' height='50px' />
        </Grid>
        {/* <Grid item xs={7}>
          <TextField fullWidth variant='outlined' />
        </Grid> */}
        <Grid item xs={7}>
          <TextField
            fullWidth
            variant='outlined'
            label='Sipp code'
            value={order.sippCode}
            onChange={e => setOrder({ ...order, sippCode: e.target.value.toUpperCase() })}
          />
        </Grid>
        <Grid item xs={5}>
          <Select
            variant='outlined'
            value={order.carGroup}
            onChange={e => setOrder({ ...order, carGroup: e.target.value })}
          >
            <MenuItem value='A'>A</MenuItem>
            <MenuItem value='B'>B</MenuItem>
            <MenuItem value='C'>C</MenuItem>
            <MenuItem value='D'>D</MenuItem>
            <MenuItem value='E'>E</MenuItem>
            <MenuItem value='F'>F</MenuItem>
            <MenuItem value='G'>G</MenuItem>
            <MenuItem value='H'>H</MenuItem>
            <MenuItem value='I'>I</MenuItem>
            <MenuItem value='IW'>IW</MenuItem>
            <MenuItem value='J'>J</MenuItem>
            <MenuItem value='K'>K</MenuItem>
            <MenuItem value='KX'>KX</MenuItem>
            <MenuItem value='L'>L</MenuItem>
            <MenuItem value='L'>LW</MenuItem>
            <MenuItem value='M'>M</MenuItem>
            <MenuItem value='MH'>MH</MenuItem>
            <MenuItem value='O'>O</MenuItem>
            <MenuItem value='P'>P</MenuItem>
            <MenuItem value='Q'>Q</MenuItem>
            <MenuItem value='R'>R</MenuItem>
            <MenuItem value='T'>T</MenuItem>
            <MenuItem value='U'>U</MenuItem>
            <MenuItem value='V'>V</MenuItem>
            <MenuItem value='V8'>V8</MenuItem>
            <MenuItem value='W'>W</MenuItem>
            <MenuItem value='Y'>Y</MenuItem>
            <MenuItem value='Z'>Z</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  );
};

export default CarDetails;
