import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';

const VoucherRemarks = ({ order, setOrder }) => {
  const defaultStamps = order.voucher.stamps.headerStamps || [];
  const [selectedStamps, setselectedStamps] = useState(defaultStamps);

  useEffect(() => {
    setOrder({
      ...order,
      voucher: { stamps: { headerStamps: selectedStamps } },
    });
  }, [selectedStamps]);

  const stamps = [
    { code: 'scdw_sthw', name: 'Super CDW Super THW' },
    { code: 'pp', name: 'Premium Protection Package' },
    { code: 'eri', name: 'Excess refund included' },
    { code: 'twc', name: 'Tyres & Windows coverage' },
  ];
  // useEffect(() => {
  //   console.log('selectedStamps', selectedStamps);
  // }, [selectedStamps]);

  const handleChange = (value) => {
    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.code === item.code) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.code !== item.code);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setselectedStamps(duplicateRemoved);
  };
  return (
    <>
      <Typography variant="h6" style={{ textAlign: 'center' }}>
        Voucher remarks
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-mutiple-checkbox-label">Stamps</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              multiple
              value={selectedStamps}
              onChange={({ target: { value } }) => handleChange(value)}
              renderValue={(selected) => selected.map((x) => x.name).join(', ')}
            >
              {stamps.map((stamp) => {
                return (
                  <MenuItem key={stamp.code} value={stamp}>
                    <Checkbox
                      checked={
                        selectedStamps.findIndex(
                          (item) => item.code === stamp.code
                        ) >= 0
                      }
                    />
                    <ListItemText primary={stamp.name} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default VoucherRemarks;
