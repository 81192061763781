import { Dialog, Divider, Grid, Paper, Typography } from '@mui/material';
import { changeCommissionFee, getAllApis } from 'apiRequests';
import React, { useState, useEffect, useRef } from 'react';
import ApiCard from './Components/ApiCard';
import ChangeCommissionFeeDialog from './Components/Dialogs/ChangeCommissionFeeDialog';

const ApiManaging = () => {
  const [apis, setApis] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState({ status: false, api: {} });

  const handleClose = () => setOpen({ status: false, api: {} });

  useEffect(() => {
    getAllApis()
      .then((res) => {
        setApis(res.data);
      })
      .catch((err) => {
        console.log(new Error(err));
      });
  }, [toggle]);

  const handleCommissionChange = (apiType, commissionFee) => {
    changeCommissionFee(apiType, commissionFee)
      .then(() => {
        alert('Commission fee has been changed successfully');
        setToggle((prevState) => !prevState);
        setOpen({ status: false, api: {} });
      })
      .catch(() => alert('Failed'));
  };
  return (
    <Paper style={{ width: 500, height: 500, padding: 16 }}>
      <Typography style={{ marginBottom: 16 }} variant="h5">
        Api Managing
      </Typography>
      <Divider />

      <Grid container style={{ marginTop: 16 }}>
        <Grid item>
          {apis.length > 0 &&
            apis.map((api) => {
              return <ApiCard key={api.apiName} setOpen={setOpen} api={api} />;
            })}
        </Grid>
      </Grid>
      {open.api.commissionFeePercent && (
        <ChangeCommissionFeeDialog
          open={open}
          handleClose={handleClose}
          handleCommissionChange={handleCommissionChange}
        />
      )}
    </Paper>
  );
};

export default ApiManaging;
