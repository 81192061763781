import React, { useState } from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const InputItem = ({ fieldName, handleInputChange }) => (
  <TextField autoComplete='none' fullWidth label={fieldName} onChange={handleInputChange} />
);

const NewLeadForm = () => {
  const [lead, setLead] = useState({
    firstName: '',
    lastName: '',
    email: '',
    age: '',
    phone: '',
    source: '',
    remarks: '',
  });

  const handleInputChange = (name, value) => {
    setLead({ ...lead, [name]: value });
  };
  const selectValues = ['Phone', 'Adwords', 'Return Customer'];

  return (
      <Grid container justify='space-between' spacing={1} style={{ maxWidth: '800px' }}>
        <Grid item lg={4} sm={4} xl={4} style={{ padding: '8px' }}>
          <div
            style={{
              border: '0.5px solid ',
              borderRadius: '4px',
              borderColor: '#C4C4C4',
              padding: '14px',
            }}
          >
            <InputItem
              fieldName='First Name'
              handleInputChange={e => handleInputChange('firstName', e.target.value)}
            />
            <InputItem
              fieldName='Last Name'
              handleInputChange={e => handleInputChange('lastName', e.target.value)}
            />
            <InputItem fieldName='Age' handleInputChange={e => handleInputChange('age', e.target.value)} />
            <InputItem
              fieldName='Email'
              handleInputChange={e => handleInputChange('email', e.target.value)}
            />
            <InputItem
              fieldName='Phone'
              handleInputChange={e => handleInputChange('phone', e.target.value)}
            />
            <FormControl fullWidth style={{ margiTtop: '8px' }}>
              <InputLabel>Source</InputLabel>
              <Select
                fullWidth
                label='Source'
                value={lead.source}
                onChange={e => setLead({ ...lead, source: e.target.value })}
              >
                {selectValues.map(value => {
                  return (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>

        <Grid item lg={8} sm={8} style={{ padding: '8px' }}>
          <TextField
            fullWidth
            variant='outlined'
            label='Remarks'
            multiline
            rows={16}
            rowsMax={20}
            value={lead.quotation}
            onChange={e => handleInputChange('remarks', e.target.value)}
          />
        </Grid>
      </Grid>
  );
};

export default NewLeadForm;
