import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import suppliers from '../../../../../data/suppliers';
import SectionTitle from './SectionTitle';

const ReservationDetails = ({ order, setOrder }) => {
  let sortedSuppliers = suppliers.sort((s1, s2) => {
    if (s1.code < s2.code) return -1;
    if (s1.code > s2.code) return 1;
    return 0;
  });
  // console.log('ORDER', order);
  const handleSupplierReservationDataChange = (name, value) => {
    setOrder({
      ...order,
      supplierReservationData: {
        ...order.supplierReservationData,
        [name]: value
      }
    });
  };
  const { contractNumber, accountNumber, reservationNumber } = order.supplierReservationData;
  return (
    <>
      <SectionTitle title='Reservation details' />
      <Grid container spacing={1} justify='space-between'>
        <Grid size={{ xs: 12 }}>
          <TextField
            size='small'
            fullWidth
            label='Supplier confirmation'
            value={reservationNumber}
            onChange={({ target: { value } }) =>
              setOrder({
                ...order,
                supplierReservationData: {
                  ...order.supplierReservationData,
                  reservationNumber: value
                }
              })
            }
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <FormControl fullWidth size='small'>
            <InputLabel>Supplier</InputLabel>

            <Select
              fullWidth
              label='Supplier'
              value={order.supplier.code}
              onChange={e =>
                setOrder({
                  ...order,
                  supplier: suppliers.find(supplier => supplier.code === e.target.value)
                })
              }
            >
              {sortedSuppliers.map((supplier, idx) => (
                <MenuItem value={supplier.code} key={idx}>
                  {supplier.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 6 }}>
          <TextField
            size='small'
            fullWidth
            label='Account no.'
            name='accountNumber'
            value={accountNumber}
            onChange={e => handleSupplierReservationDataChange(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <TextField
            size='small'
            fullWidth
            label='Contract no.'
            name='contractNumber'
            value={contractNumber}
            onChange={e => handleSupplierReservationDataChange(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <TextField
            size='small'
            InputProps={{
              readOnly: true
            }}
            fullWidth
            label='Order Number'
            name='orderId'
            value={order.orderId}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ReservationDetails;
