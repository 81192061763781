import React from 'react'
import { Grid } from '@mui/material'

const Order = () => {
    return (
        <Grid container spacing={1} style={{width:'1280px', height:'800px'}}>
            <Grid item >ORDER</Grid>
        </Grid>
    )
}

export default Order
