import React, { useState } from 'react';
import {
  Dialog,
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  TextField,
  Grid
} from '@mui/material';
import { Transition } from '../../assets';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import AddQuotationDialog from '../Quotes/Components/AddQuotationDialog';

const FormItem = ({ value, label }) => (
  <Grid item sm={6} xs={12}>
    <TextField
      fullWidth
      margin='dense'
      label={label}
      defaultValue={value}
      variant='outlined'
      inputProps={{
        readOnly: true
      }}
    />
  </Grid>
);

const RequestField = styled(TextField)`
  .MuiInputBase-root
    .MuiOutlinedInput-root
    .MuiInputBase-fullWidth
    .MuiInputBase-formControl
    .MuiInputBase-multiline
    .MuiOutlinedInput-multiline
    .MuiInputBase-marginDense
    .MuiOutlinedInput-marginDense {
    height: 189px;
  }
  .MuiOutlinedInput-root {
    height: 210px;
  }
`;
const LeadDescription = ({
  open: { leadDescription, quotation },
  handleLeadDescriptionClose,
  lead,
  handleQuotationOpen,
  handleQuotationClose
}) => {
  const {
    firstName,
    lastName,
    phone,
    email,
    lead: { source, comment, remarks, createdBy, creationDate }
  } = lead;
  const date = new Date(creationDate).toLocaleDateString('en-GB');
  // const time = comment.length > 0 && date.getHours().toString() + ':' + date.getMinutes().toString();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      maxWidth='lg'
      open={leadDescription}
      onClose={handleLeadDescriptionClose}
      TransitionComponent={Transition}
    >
      <AppBar position='static'>
        <Toolbar>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <IconButton edge='start' color='inherit' onClick={handleLeadDescriptionClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='h6' style={{ color: 'white' }}>
                Lead description
              </Typography>
            </div>

            <Button autoFocus color='inherit' onClick={handleLeadDescriptionClose}>
              save
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Container>
        <Grid
          container
          spacing={1}
          style={{ marginTop: '16px', padding: '16px 16px 0 16px', marginBottom: '8px' }}
        >
          <Grid item sm={6} xs={12}>
            <Grid container>
              <Grid item>
                <Grid container spacing={1}>
                  <FormItem value={(firstName || 'Unknown', firstName)} label='First name' />
                  <FormItem value={(lastName || 'Unknown', lastName)} label='Last name' />
                  <FormItem value={email || 'Unknown'} label='Email' />
                  <FormItem value={phone || 'Unknown'} label='Phone' />
                  {/* <Grid item xs={12}> */}
                  {/* <TextField
                        fullWidth
                        margin="dense"
                        label="Source"
                        defaultValue={source}
                        variant="outlined"
                        inputProps={{
                          readOnly: true
                        }}
                      />
                    </Grid> */}
                  <FormItem value={source || 'Unknown'} label='Source' />
                  <FormItem
                    value={comment.selectedCar !== undefined ? comment.selectedCar : ''}
                    label='Selected Car'
                  />

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin='dense'
                      label='Lead date'
                      defaultValue={date && date.toString()}
                      variant='outlined'
                      inputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <FormItem value={createdBy || 'Unknown'} label='Created by' />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12} style={{ display: 'flex' }}>
            <Grid item md={6} style={{ marginRight: '8px' }}>
              <RequestField
                margin='dense'
                fullWidth
                label='Request Comment'
                variant='outlined'
                inputProps={{
                  readOnly: true
                }}
                multiline
                rows={11}
                defaultValue={comment.message}
              />
            </Grid>
            <Grid item md={6}>
              <RequestField
                margin='dense'
                fullWidth
                label='Remarks'
                variant='outlined'
                multiline
                rows={11}
                defaultValue={remarks.length > 0 ? remarks[remarks.length - 1] : ''}
              />
            </Grid>
          </Grid>
          <Grid container justify='flex-end' style={{ margin: '0 4px 8px 0' }}>
            <Button startIcon={<AddIcon />} variant='contained' onClick={() => setOpen(true)}>
              Quotation
            </Button>
          </Grid>
        </Grid>
      </Container>
      <AddQuotationDialog {...lead} open={open} handleClose={handleClose} />
      {/* <AddQuotation
        {...lead}
        handleQuotationClose={handleQuotationClose}
        open={quotation}
      /> */}
    </Dialog>
  );
};

export default LeadDescription;
