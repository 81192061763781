import { Container, Grid, Typography } from '@mui/material';
import { getTopRentACarCars } from 'apiRequests';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CarTable from './components/CarTable/CarTable';

const ApiConfig = () => {
  const { api } = useParams();
  const [cars, setCars] = useState([]);

  useEffect(() => {
    getTopRentACarCars()
      .then(({ data }) => {
        setCars(data);
      })
      .catch(err => console.log(err));
  }, []);
  const apiName = api === 'ofran' ? 'Ofran' : 'Top Rent A Car';
  return (
    <Container>
      <Grid container style={{ paddingTop: 32 }}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='h4'>{apiName} configuration</Typography>
        </Grid>

        {api === 'toprentacar' && (
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <CarTable  cars={cars}/>
          </Grid>
        )}
        {api === 'ofran' && <div>ofran config here</div>}
      </Grid>
    </Container>
  );
};

export default ApiConfig;
