import React from 'react';
import { Link as RouterLink, Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Avatar, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;
  const user  = JSON.parse(localStorage.getItem('user'));
  const classes = useStyles();
 

  const start =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : 'https://crm.we4rent.com';

  const userImage = user.image;

  return (
    <div {...rest} className={clsx(classes.root, className)} to={'/account'}>
      <Avatar
        alt={user.userName}
        className={classes.avatar}
        component={RouterLink}
        src={user.image}
        to='/account'
      />
      <Typography className={classes.name} variant='h4'>
        {user.name}
      </Typography>
      <Typography variant='body2'>{user.position}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
