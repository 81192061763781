import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import { EditingState, IntegratedFiltering, SearchState } from '@devexpress/dx-react-grid';
// import debounce from 'lodash.debounce';

import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableBandHeader,
  Toolbar,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';
import { deleteLocations, getAllCountryLocations, saveChangedRow, saveNewLocations } from 'apiRequests';
import {
  stationsComparator,
  getExtensions,
  getColumns,
  getColumnsBands,
  getEditingColumnExtensions
} from '../../helperFunctions';
import BandCell from './Components/BandCell';

const LocationsTable = ({ selectedCountry }) => {
  const [countryLocations, setCountryLocations] = useState([]);

  useEffect(() => {
    getAllCountryLocations(selectedCountry.code)
      .then(({ data }) => {
        setCountryLocations(data.sort(stationsComparator));
      })
      .catch(err => {
        console.log(new Error(err));
      });
  }, [selectedCountry]);

  const [columns] = useState(getColumns());
  const [columnBands] = useState(getColumnsBands());
  const [tableColumnExtensions] = useState(getExtensions());

  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(
      countryLocations.map((loc, id) => {
        return { ...loc, id };
      })
    );
  }, [countryLocations]);
  useEffect(() => {
    console.log(rows);
  }, [rows]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [editingColumnExtensions] = useState(getEditingColumnExtensions());

  const changeAddedRows = value => {
    const initialized = value.map(row => {
      return Object.keys(row).length ? row : { country: selectedCountry };
    });
    setAddedRows(initialized);
  };
  const getRowId = row => row.id;

  const commitChanges = async ({ added, changed, deleted }) => {
    let changedRows;
    let success = false;
    if (added) {
      const { data } = await saveNewLocations(added);
      success = data.status === 'OK';
      if (data.status === 'failed') {
        alert('There is a problem with inserting new locations');
      } else {
        changedRows = [
          ...added.map((row, index) => ({
            id: 0,
            ...row
          })),
          ...rows.map(item => {
            item.id = item.id + 1;
            return item;
          })
        ];
      }
    }
    if (changed) {
      try {
        console.log('CHANGED', changed);
        const key = Object.keys(changed)[0];
        const { data } = await saveChangedRow(changed[key]);
        success = data.status === 'OK';
        if (data.status === 'failed') {
          alert('Failed to delete the location');
        } else {
          changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
        }
      } catch (err) {
        console.log(new Error(err));
        alert('Something went wrong');
      }
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      const rowsToDelete = [];
      changedRows = rows.filter(row => {
        if (deletedSet.has(row.id) && row._id) {
          rowsToDelete.push(row);
        }
        return !deletedSet.has(row.id);
      });

      const { data } = await deleteLocations(rowsToDelete);
      success = data.status === 'OK';
      if (data.status === 'failed') {
        alert('Failed to delete the rows');
      }
    }
    if (success) {
      setRows(changedRows);
    }
  };
  const validate = (rows, columns) =>
    Object.entries(rows).reduce(
      (acc, [rowId, row]) => ({
        ...acc,
        [rowId]: columns.some(column => column.required && row[column.name] === '')
      }),
      {}
    );
  // const onAdded = debounce(edited => setErrors(validate(edited, columns)), 250);

  return (
    <Paper>
      <Grid rows={rows} columns={columns} getRowId={row => row.id}>
        <SearchState defaultValue='' />
        <EditingState
          columnExtensions={editingColumnExtensions}
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={setEditingRowIds}
          rowChanges={rowChanges}
          onRowChangesChange={row => {
            setRowChanges();
            console.log(row);
          }}
          addedRows={addedRows}
          onAddedRowsChange={changeAddedRows}
          onCommitChanges={commitChanges}
        />
        <IntegratedFiltering />

        <VirtualTable height='70vh' columnExtensions={tableColumnExtensions} />
        <TableHeaderRow cellComponent={BandCell} />
        <Toolbar />
        <SearchPanel />

        <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
        <TableEditRow />

        <TableBandHeader columnBands={columnBands} cellComponent={BandCell} />
      </Grid>
    </Paper>
  );
};

export default LocationsTable;
