import { TextField, Typography } from '@mui/material';
import React from 'react';

const NotIncluded = ({ order, setOrder }) => {
  const handleChange = e => {
    const values = e.target.value.split('\n');
    setOrder({ ...order, notIncluded: values });
  };
  return (
    <>
      <Typography variant='h6' style={{ textAlign: 'center', marginBottom: 8 }}>
        Not included
      </Typography>
      <TextField multiline rows={4} variant='outlined' fullWidth onChange={handleChange} />
    </>
  );
};

export default NotIncluded;
