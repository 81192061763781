import React from 'react'
import { TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Typography } from '@mui/material'
import { roundUpToTwoDigits } from '../../../../../../../helpers/utils'
import { nameMapping } from '../utils'
const TotalTable = ({ quote, language, totalPrice }) => {
    const { protections, numberOfDays, userExtras, oneWayFee } = quote;
    const protectionsRows = []
    const extrasRows = []
    // let total = roundUpToTwoDigits(quote.carBasePrice * numberOfDays);

    for (const protection in protections) {
        if (protections[protection].chosen) {
            // total += roundUpToTwoDigits(protections[protection].price * numberOfDays)
            protectionsRows.push({
                name: protection === 'three_PLC' ? 'LIA'
                    : protection === 'superCDW' ? 'Super CDW'
                        : protection === 'superTP' ? 'Super TP'
                            : protection, count: '', price: roundUpToTwoDigits((protections[protection].price) * numberOfDays)
            })
        }
    }
    for (const extra in userExtras) {
        if (userExtras[extra].count > 0) {
            extrasRows.push({ name: nameMapping(extra, language), count: userExtras[extra].count, price: roundUpToTwoDigits(userExtras[extra].price * userExtras[extra].count * numberOfDays) })
        }
    }
    return (
        <TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">{language === 'en' ? 'Count' : 'Количество'}</TableCell>
                        <TableCell align="right">{language === 'en' ? 'Price' : 'Цена'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* {quote.carBasePrice > 0 && */}
                    <TableRow >
                        <TableCell>{language === 'en' ? 'Car base price' : 'Базовая цена'}</TableCell>
                        <TableCell>{numberOfDays > 0 ? `${numberOfDays} ${language === 'en' ? 'days' : 'дней'}` : ''}</TableCell>
                        <TableCell>{roundUpToTwoDigits(quote.carBasePrice * numberOfDays)}</TableCell>
                    </TableRow>
                    {/* } */}

                    {/**Protections  */}
                    {protectionsRows.map(protection => (
                        <TableRow key={protection.name}>
                            <TableCell>{protection.name}</TableCell>
                            <TableCell>{protection.count}</TableCell>
                            <TableCell>{protection.price}</TableCell>
                        </TableRow>
                    ))}
                    {/**End protections */}
                    {/**Extras */}
                    {extrasRows.map(extra => (
                        <TableRow key={extra.name}>
                            <TableCell>{extra.name}</TableCell>
                            <TableCell>{extra.count}</TableCell>
                            <TableCell>{extra.price}</TableCell>
                        </TableRow>
                    ))}
                    {/**End Extras */}
                    {oneWayFee > 0 && < TableRow >
                        <TableCell>{language === 'en' ? "One way fee" : "Поездка в одну сторону"}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{quote.oneWayFee}</TableCell>
                    </TableRow>}

                    <TableRow>
                        <TableCell rowSpan={2} />
                        <TableCell align='right' colSpan={1} >
                            <Typography variant='h6'>{language === 'en' ? 'Total' : "Всего"}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ fontWeight: 'bold' }}>
                            <Typography variant='h6'>{roundUpToTwoDigits(totalPrice)}</Typography>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer >
    )
}

export default TotalTable
