import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import TableComponent from './TableComponent';

const HolidaysTable = ({ holidays, stopSale, handleOpenDialog }) => {
  const [rows, setRows] = useState(holidays);
  const [stopSaleRows, setStopSaleRows] = useState(stopSale);
  //   useEffect(() => {
  //     console.log('ROWS', rows);
  //     console.log('stop sale', stopSaleRows);
  //   }, [rows, stopSaleRows]);

  useEffect(() => {
    setRows(holidays);
    setStopSaleRows(stopSale);
  }, [holidays, stopSale]);

  return (
    <>
      <TableContainer component={TableComponent}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Holiday</TableCell>
              <TableCell align='center'>From</TableCell>
              <TableCell align='center'>Until</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell component='th' scope='row'>
                    {row.holidayName.en}
                  </TableCell>
                  <TableCell align='center'>{format(new Date(row.from), 'dd.MM.yyyy')}</TableCell>
                  <TableCell align='center'>
                    {format(new Date(row.until.split('T')[0]), 'dd.MM.yyyy')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Button style={{ marginLeft: 8 }} onClick={() => handleOpenDialog('addNewHoliday', true)}>
          Add new holiday
        </Button>
      </TableContainer>
      <TableContainer component={TableComponent}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Stop sale</TableCell>
              <TableCell align='center'>From</TableCell>
              <TableCell align='center'>Until</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stopSaleRows &&
              stopSaleRows.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell component='th' scope='row'>
                    {'Stop Sale'}
                  </TableCell>
                  <TableCell align='right'>{format(new Date(row.from), 'dd.MM.yyyy')}</TableCell>
                  <TableCell align='right'>
                    {format(new Date(row.until.split('T')[0]), 'dd.MM.yyyy')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Button
          onClick={() => handleOpenDialog({ dialogName: 'StopSaleDialog', isOpen: true })}
          style={{ marginLeft: 8 }}
        >
          Add Stop sale dates
        </Button>
      </TableContainer>
    </>
  );
};

export default HolidaysTable;
