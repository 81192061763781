import { Button, Chip, Input, MenuItem, Paper, Select, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Grid, TableEditColumn, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import { updateCar } from 'apiRequests';
import { StyledChip } from 'views/SimCards/SellTable/styles/SellTable.styled';
import PopupEditing from './components/PopupEditing';
import Popup from './components/Popup/Popup';

const CurrencyFormatter = ({ value }) => {
  const formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'EUR' });
  return <>{formatter.format(value)}</>;
};
const CurrencyTypeProvider = props => <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />;

const CarTable = ({ cars }) => {
  const { palette } = useTheme();
  const BooleanEditor = ({ value, onValueChange }) => (
    <Select
      input={<Input />}
      value={value ? 'Yes' : 'No'}
      onChange={event => onValueChange(event.target.value === 'Yes')}
      style={{ width: '100%' }}
    >
      <MenuItem value='Yes'>Yes</MenuItem>
      <MenuItem value='No'>No</MenuItem>
    </Select>
  );
  const BooleanFormatter = ({ value }) => (
    <StyledChip label={value ? 'Yes' : 'No'} bg={value ? palette.success.main : '#E0E0E0'} />
  );
  const BooleanTypeProvider = props => (
    <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanEditor} {...props} />
  );
  const getCellCoveragePriceByCode = (row, code) => {
    if (row.prices) {
      const coverage = row.prices.protection_coverage.find(c => c.code === code);
      if (coverage) {
        return coverage.price;
      } else return '';
    } else return '';
  };

  const [columns] = useState([
    { name: 'modelName', title: 'Model' },
    { name: 'sippCode', title: 'Sipp Code' },
    { name: 'year', title: 'Year' },
    { name: 'isModelGuaranteed', title: 'Model Guaranteed' },
    {
      name: 'FC',
      title: 'Top coverage',
      getCellValue: row => getCellCoveragePriceByCode(row, 'FC')
    },
    { name: 'TW', title: 'Tyres windows', getCellValue: row => getCellCoveragePriceByCode(row, 'TW') }
  ]);
  const [currencyColumns] = useState(['FC', 'TW']);

  const [booleanColumns] = useState(['isModelGuaranteed']);
  const [tableColumnExtensions] = useState([{ columnName: 'modelName', width: '250px' }]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      cars.map((car, id) => {
        return { ...car, id };
      })
    );
  }, [cars]);
  const commitChanges = async ({ added, changed, deleted }) => {
    let isCommitting = true;
    let changedRows;
    if (added) {
      console.log(' COMMIT HERE', added);
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row
        }))
      ];
    }
    if (changed) {
      const rowID = Object.keys(changed)[0];
      const changes = changed[rowID];
      const changedRow = rows[rowID];
      const { _id } = changedRow;
      if (changes) {
        try {
          let {
            data: { status, reason }
          } = await updateCar(_id, changes);
          if (status === 'success') {
            isCommitting = true;
            alert('SUCCESS. The car has been updated');
          }
          if (status === 'failed') {
            alert('FAILED. The car has not been updated', reason);
          }
        } catch (err) {
          console.log(err);
          alert('FAILED. Internal server error');
        }
      }

      if (isCommitting) {
        changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
        console.log('CHANGED ROW', changedRow);
      } else {
        changedRows = rows;
      }
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter(row => !deletedSet.has(row.id));
    }
    setRows(changedRows);
  };

  return (
    <Paper>
      <Grid rows={rows} columns={columns}>
        <VirtualTable height='auto' columnExtensions={tableColumnExtensions} />
        <TableHeaderRow />
        <BooleanTypeProvider for={booleanColumns} />
        <CurrencyTypeProvider for={currencyColumns} />
        <EditingState onCommitChanges={commitChanges} />
        {/* <TableEditRow /> */}
        <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
        <PopupEditing popupComponent={Popup} />
      </Grid>
    </Paper>
  );
};

export default CarTable;
