import {
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Select,
  Switch,
  Typography,
  Grid,
  Button,
  Tooltip
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import styled from 'styled-components';
import { BlockTitle, HeadBlock } from '../styles/TopBar.styled';



const StyledTooltip = styled(Tooltip)``;

const TopBar = ({
  setDialogOpen,
  carsPrices,
  setOpen,
  value,
  handleChange,
  handleActiveChange,
  isActive,
  handleDeletePriceList,
  handlePromoChange,
  isPromo
}) => {
  return (
    <Paper style={{ marginBottom: 8, width: '100%', padding: 8 }}>
      <Grid container justifyContent='space-between'>
        {' '}
        <Grid item md={8} style={{ display: 'flex', alignItems: 'center' }}>
          <HeadBlock container spacing={1}>
            <Grid item md={3}>
              <BlockTitle variant='h6'>Price list</BlockTitle>
            </Grid>
            <Grid item sm={3} style={{ display: 'flex', alignItems: 'center' }}>
              <Select value={value} onChange={handleChange} fullWidth>
                {carsPrices &&
                  carsPrices.map((pl, idx) => {
                    return (
                      <MenuItem value={idx} key={pl._id}>
                        <Typography variant='h6'>{pl.priceListName}</Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
              <StyledTooltip
                title={
                  <Typography style={{ color: '#FFF' }} variant='h6'>
                    Delete this price list
                  </Typography>
                }
              >
                <Button
                  onClick={
                    () => setDialogOpen({ dialogName: 'DeletePriceList', isOpen: true })
                    // handleDeletePriceList(carsPrices[value]._id, carsPrices[value].priceListName)
                  }
                >
                  <DeleteForeverIcon />
                </Button>
              </StyledTooltip>
              <Tooltip
                title={
                  <Typography variant='h6' style={{ color: '#FFF' }}>
                    Edit Price list name
                  </Typography>
                }
              >
                <Button
                  onClick={() => setDialogOpen({ dialogName: 'EditPriceListNameDialog', isOpen: true })}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item md={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl component='fieldset'>
                <FormGroup row>
                  <FormControlLabel
                    onChange={handleActiveChange}
                    control={<Switch color='primary' checked={isActive} />}
                    label={<Typography variant='h6'>Active</Typography>}
                    labelPlacement='start'
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item md={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl component='fieldset'>
                <FormGroup row>
                  <FormControlLabel
                    onChange={handlePromoChange}
                    control={<Switch color='primary' checked={isPromo} />}
                    label={<Typography variant='h6'>Promo</Typography>}
                    labelPlacement='start'
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item>
              <Typography variant='h6'>Currency {carsPrices[value].currency} </Typography>
            </Grid>
          </HeadBlock>
        </Grid>
        {/* <Grid item>
            <HeadBlock container>
              <BlockTitle variant='h6'>Supplier - Europcar</BlockTitle>
            </HeadBlock>
          </Grid> */}
        <Grid item>
          <HeadBlock>
            <Button onClick={() => setOpen(true)}>Create new price list</Button>
          </HeadBlock>
        </Grid>
        <Grid item>
          <HeadBlock>
            <Button onClick={() => setDialogOpen({ dialogName: 'StopSaleDialog', isOpen: true })}>
              Stop sale
            </Button>
          </HeadBlock>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TopBar;
