import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'; // choose your lib
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import React, { useEffect, useState } from 'react';
import { endOfDay, startOfDay } from 'date-fns';

const CreateNewEventDialog = ({ open, handleSubmit, setOpen }) => {
  const [event, setEvent] = useState({
    type: 'holiday',
    country: { code: 'il', name: 'Israel' },
    supplier: { name: 'Europcar', code: 'europcar' },
    holidayType: 'holiday',
    from: new Date(),
    until: new Date()
  });

  useEffect(() => {
    console.log(event);
  }, [event]);
  const handleTypeChange = value => {
    setEvent({ ...event, type: value });
  };

  const handleDateChange = (type, date) => {
    setEvent({ ...event, [type]: type === 'from' ? startOfDay(date) : endOfDay(date) });
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{'Create new event'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ border: '1px solid #E4E4E4', padding: '32px 16px' }}>
          <Grid item sm={12}>
            Country: {event.country.name}
          </Grid>{' '}
          <Grid style={{ marginBottom: 16 }} item sm={12}>
            Supplier: {event.supplier.name}
          </Grid>
          <Grid style={{ marginBottom: 16 }} item xs={12} sm={4}>
            <FormControl variant='outlined' size='small' fullWidth style={{ width: 200 }}>
              <InputLabel>Type</InputLabel>
              <Select
                value={event.type}
                label='Type'
                onChange={({ target: { value } }) => handleTypeChange(value)}
              >
                <MenuItem value={'stopsale'}>Stop Sale</MenuItem>
                <MenuItem value={'holiday'}>Holidays</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={8}></Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item>
              {' '}
              From {event.type === 'holiday' && '(holiday evening)'}:{' '}
              <DatePicker
                style={{ marginLeft: 8 }}
                size='small'
                value={event.from}
                onChange={date => handleDateChange('from', date)}
              />
            </Grid>{' '}
            <Grid item>
              {' '}
              Until {event.type === 'holiday' && '(holiday)'}:{' '}
              <DatePicker
                style={{ marginLeft: 8 }}
                size='small'
                value={event.until}
                onChange={date => handleDateChange('until', date)}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={() => setOpen(false)}>
          cancel
        </Button>
        <Button onClick={() => handleSubmit(event)} color='primary' autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewEventDialog;
