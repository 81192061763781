import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {
  Typography,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  ListItem,
  List,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { orderURL } from '../../../../helpers';
import axios from 'axios';
import LoadingButton from 'components/LoadingButton';
import CancelConfirmationDialog from './components/CancelConfirmationDialog';
import { cancelOrder } from 'apiRequests';

const DialogHeading = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const Legend = styled.legend`
  padding: 0px 4px;
`;

const LI = ({ description, name, handleChange, value, currentOrder, ...rest }) => {
  const [isEditable, setIsEditable] = useState(false);
  return (
    <>
      <ListItem>
        <TextField
          fullWidth
          name={name}
          onDoubleClick={() => setIsEditable(true)}
          onChange={event => handleChange(event.target.value, name)}
          label={description}
          value={value}
          InputProps={{ readOnly: !isEditable }}
        />
      </ListItem>
      {/* <Divider /> */}
    </>
  );
};

const OrderDescription = ({ open, handleClose, order, setChanges, isOrder }) => {
  const [currentOrder, setCurrentOrder] = useState(order);
  const [changedValues, setChangedValues] = useState({});
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({
    type: '',
    status: false
  });
  const [cancelConfirmed, setCancelConfirmed] = useState(false);

  const handleConfirmed = () => {
    setCancelConfirmed(true);
    setConfirmationDialogOpen(false);
  };

  const handleCancelOrder = () => {
    setIsLoading({ type: 'cancel', status: true });
    setConfirmationDialogOpen(true);
  };
  useEffect(() => {
    if (cancelConfirmed) {
      cancelOrder(order.orderId)
        .then(() => {
          setIsLoading(false);
        })
        .catch(err => {
          alert('There was a problem with cancelling the order');
        })
        .finally(() => {
          setIsLoading({ type: '', status: false });
          setCancelConfirmed(false);
        });
    }
  }, [cancelConfirmed]);
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currentOrder.currencyCode || 'USD'
  });
  const handleChange = (value, name) => {
    setChangedValues({ ...changedValues, [name]: value });
    setCurrentOrder({ ...currentOrder, [name]: value });
  };
  useEffect(() => {
    setCurrentOrder(order);
  }, [order]);
  const liProps = { handleChange, currentOrder };

  const saveChangesAndClose = () => {
    let changes = false;
    if (changedValues) {
      for (let key in changedValues) {
        if (changedValues[key] !== order[key]) {
          changes = true;
          // setCurrentOrder({ ...currentOrder, [key]: changedValues[key] })
        }
      }
      if (changes) {
        const url = orderURL(
          process.env.NODE_ENV,
          `updateorder?email=${currentOrder.email}&orderId=${currentOrder.orderId}`
        );
        axios({
          method: 'POST',
          url,
          data: {
            changedValues
          }
        })
          .then(res => {
            handleClose();

            setChanges(prevState => !prevState.changes);
          })
          .catch(err => console.log(err));
      }
    }
  };
  const onClose = () => {
    setIsLoading(false);
    setCancelConfirmed(false);
    handleClose();
  };
  return (
    <>
      <Dialog maxWidth='lg' fullWidth open={open} onClose={onClose} TransitionComponent={Transition}>
        <AppBar position='static'>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <DialogHeading
              component={'span'}
              variant='h6'
              style={{ flex: 1, marginLeft: '32px', color: 'white' }}
            >
              {isOrder ? 'Order' : 'Quotation'} #{order.orderId}
            </DialogHeading>

            <LoadingButton
              name='Cancel order'
              isLoading={isLoading.type === 'cancel' && isLoading.status}
              handleClick={handleCancelOrder}
              disabled={
                (isLoading.type === 'cancel' && isLoading.status) ||
                order.status.toLowerCase() === 'cancelled'
              }
            />

            <Button autoFocus color='inherit' variant='outlined' onClick={saveChangesAndClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <DialogContentText component={'span'}>
            <div>
              <Grid component='span' container spacing={2}>
                <Grid component='span' item xs={12} md={3} style={{ border: '1px solid #eeeeee' }}>
                  <fieldset>
                    <Legend>Reservation</Legend>
                    <List>
                      <LI description='Order number' value={currentOrder.orderId} />
                      <LI description='Status' value={currentOrder.status} />
                      <LI value={formatter.format(currentOrder.total)} description='Total price' />
                    </List>
                  </fieldset>
                  <fieldset>
                    <Legend>Car</Legend>
                    <List>
                      <LI value={currentOrder.vehicle_type} description='Model' />

                      <LI value={currentOrder.sippCode} description='Sipp code' />
                      <LI value={formatter.format(currentOrder.carBasePrice)} description='Base price' />
                    </List>
                  </fieldset>
                  <fieldset>
                    <Legend style={{ padding: '0px 4px' }}>Driver details</Legend>
                    <span>
                      <List>
                        <LI
                          description={'Name: '}
                          value={
                            currentOrder.driverDetails.firstName + ' ' + currentOrder.driverDetails.lastName
                          }
                          name='firstName'
                          handleChange={handleChange}
                        />

                        <LI
                          description={'Email: '}
                          name='email'
                          value={currentOrder.driverDetails.email}
                          handleChange={handleChange}
                        />
                        <LI
                          description={'Phone: '}
                          name='phone'
                          handleChange={handleChange}
                          value={currentOrder.driverDetails.phone}
                        />
                      </List>
                    </span>
                  </fieldset>
                </Grid>

                <Grid component='span' item xs={12} md={3} style={{ border: '1px solid #eeeeee' }}>
                  <div>
                    <fieldset>
                      <Legend>Pick up and drop off details</Legend>
                      <List>
                        <LI
                          description={'Country code'}
                          name='country'
                          handleChange={handleChange}
                          currentOrder={currentOrder}
                          value={currentOrder.country.toUpperCase()}
                        />
                        <LI
                          description={'Pick Up Location: '}
                          name='pickUpLocation'
                          handleChange={handleChange}
                          currentOrder={currentOrder}
                          value={currentOrder.pickUpLocation ? currentOrder.pickUpLocation.LocationName : ''}
                        />
                        <LI
                          description={'Pick Up Date: '}
                          name='pickUpDate'
                          handleChange={handleChange}
                          value={new Date(currentOrder.pickUpDate).toLocaleDateString('en-GB')}
                        />
                        <LI
                          description={'Pick Up Time: '}
                          name='pickUpTime'
                          handleChange={handleChange}
                          value={currentOrder.pickUpTime}
                        />
                        <LI
                          description={'Drop Off Location: '}
                          name='dropOffLocation'
                          handleChange={handleChange}
                          value={currentOrder.dropOffLocation.LocationName}
                        />
                        <LI
                          description={'Drop Off Date: '}
                          name='dropOffDate'
                          {...liProps}
                          value={new Date(currentOrder.dropOffDate).toLocaleDateString('en-GB')}
                        />
                        <LI
                          description={'Drop Off Time: '}
                          name='dropOffTime'
                          {...liProps}
                          value={currentOrder.dropOffTime}
                        />
                        <LI
                          description={'Number of days'}
                          name='dropOffTime'
                          {...liProps}
                          value={currentOrder.numberOfDays}
                        />
                      </List>
                    </fieldset>
                  </div>
                </Grid>
                <Grid component='span' item xs={12} md={3} style={{ border: '1px solid #eeeeee' }}>
                  <fieldset>
                    <Legend>Extras</Legend>
                    <List>
                      {currentOrder.userExtras
                        ? currentOrder.userExtras.map(extra => {
                            return (
                              <ListItem>
                                <TextField
                                  fullWidth
                                  multiline
                                  value={`Code: ${extra.code}\nName: ${extra.name}\nCount: ${
                                    extra.count
                                  }\nPrice: ${formatter.format(extra.price)}`}
                                />
                              </ListItem>
                            );
                          })
                        : ''}
                    </List>
                  </fieldset>
                </Grid>
                <Grid component='span' item xs={12} md={3} style={{ border: '1px solid #eeeeee' }}>
                  <fieldset>
                    <Legend>Protections</Legend>
                    <List>
                      {currentOrder.protections
                        ? currentOrder.protections.map(protection => {
                            return (
                              <ListItem>
                                <TextField
                                  fullWidth
                                  multiline
                                  value={`Code: ${protection.code}\nName: ${
                                    protection.name
                                  }\nPrice: ${formatter.format(protection.price)}`}
                                />
                              </ListItem>
                            );
                          })
                        : ''}
                    </List>
                  </fieldset>
                  <fieldset>
                    <Legend>Other</Legend>
                    <List>
                      <LI description='Flight' value={currentOrder.flightNumber} />
                    </List>
                  </fieldset>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>

                    <Button onClick={handleClose} color="primary" autoFocus>
                        Ok
          </Button>
                </DialogActions> */}
      </Dialog>
      <CancelConfirmationDialog
        setOpen={setConfirmationDialogOpen}
        open={confirmationDialogOpen}
        handleConfirmed={handleConfirmed}
      />
    </>
  );
};

export default OrderDescription;
