import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

const CancelConfirmationDialog = ({ open, setOpen, handleConfirmed }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{'Do you want to cancel the order?'}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button variant='outlined' onClick={handleConfirmed}>
          Yes
        </Button>
        <Button variant='outlined' onClick={handleClose} autoFocus>
          no
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelConfirmationDialog;
