import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';

const messages = {
  success: {
    message: 'The order has been saved'
  },
  error: {
    message: 'ERROR OCCURRED!!! The order has not been saved'
  }
};

const OrderSavedMessage = ({ open, severity, handleMessageSavedDialogClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleMessageSavedDialogClose}>
      <Alert severity={severity}>{messages[severity].message}</Alert>
    </Snackbar>
  );
};

export default OrderSavedMessage;
