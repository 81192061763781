import { Container, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CountriesSelection from './components/CountriesSelection';
import LocationsTable from './components/LocationsTable/LocationsTable';

const Locations = () => {
  const [selectedCountry, setSelectedCountry] = useState('');
  useEffect(() => {
    console.log('selectedCountry', selectedCountry);
  }, [selectedCountry]);
  return (
    <Container style={{ padding: 32 }}>
      <Grid container>
        <Grid item sm={3}>
          <CountriesSelection setSelectedCountry={setSelectedCountry} selectedCountry={selectedCountry}/>
        </Grid>
      </Grid>
      <div style={{ marginTop: 32 }}>
        <LocationsTable selectedCountry={selectedCountry} />
      </div>
    </Container>
  );
};

export default Locations;
