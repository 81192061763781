import { Button, Container, Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import NewSaleDialog from './Dialogs/NewSaleDialog';
import SellTable from './SellTable/SellTable';
import _ from 'lodash';
import { getAllSimCards, saveNewSimCardSale } from 'apiRequests';

const SimCards = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [simCards, setSimCards] = useState([]);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    getAllSimCards()
      .then(res => {
        setSimCards(res.data);
      })
      .catch(err => {
        console.log(new Error(err));
      });
  }, [toggle]);
//   useEffect(() => {
//     console.log(toggle);
//   }, [toggle]);
//   useEffect(() => {
//     console.log(simCards);
//   }, [simCards]);
  const formik = useFormik({
    initialValues: {
      simCardOrderId: _.random(1000000),
      operator: 'Pelephone',
      phoneNumber: String,
      orderReference: null,
      firstName: '',
      lastName: '',
      price: null,
      currency: 'EUR'
    },
    onSubmit: (values, actions) => {
      setIsLoading(true);
      saveNewSimCardSale(values)
        .then(res => {
          setIsOpen(false);
          setToggle(prevState => !prevState);
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
          alert("Couldn't save the new sim card");
        });
    }
  });
  //   useEffect(() => {
  //     console.log(formik.values);
  //   }, [formik.values]);
  return (
    <Container style={{ padding: 32 }}>
      <Grid container>
        <Grid item>
          <Button onClick={() => setIsOpen(true)} variant='contained'>
            New Sale
          </Button>
        </Grid>
      </Grid>
      <div style={{ marginTop: 32 }}>
        <SellTable simCards={simCards} />
      </div>
      <NewSaleDialog
        isLoading={isLoading}
        setFieldValue={formik.setFieldValue}
        values={formik.values}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={formik.handleSubmit}
      />
    </Container>
  );
};

export default SimCards;
