import { TextField, Typography } from '@mui/material';
import React from 'react';

const Policies = ({ order, setOrder }) => {
  const handleChange = e => {
    const values = e.target.value.split('\n');
    setOrder({ ...order, policies: values });
  };
  return (
    <>
      <Typography style={{ textAlign: 'center', marginBottom: 8 }} variant='h6'>
        Policies
      </Typography>
      <TextField fullWidth multiline rows={4} variant='outlined' onChange={handleChange} />
    </>
  );
};

export default Policies;
