import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { savePromoDates } from 'apiRequests';
import React, { useState } from 'react';

const AddPromoDialog = ({ open, handleOpenDialog, priceListID, setChanges }) => {
  const [from, setFrom] = useState(new Date());
  const [until, setUntil] = useState(new Date());

  const handleAddPromoDates = () => {
    savePromoDates(from, until, priceListID)
      .then(res => {
        alert(res.data.message);
        setChanges(prevState => ({
          changes: !prevState.changes
        }));
      })
      .catch(err => {
        console.log(err);
        alert(err.response.data.message);
      })
      .finally(handleOpenDialog('addPromoDialog', false));
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle style={{ textAlign: 'center' }}>Choose dates</DialogTitle>
      <DialogContent style={{ paddingBottom: 16 }}>
        <Container style={{ textAlign: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={1} direction='row'>
              <Grid item xs={12} md={6}>
                <DatePicker
                  fullWidth
                  style={{ marginRight: 16 }}
                  label='From'
                  value={from}
                  onChange={setFrom}
                  animateYearScrolling
                  inputVariant='outlined'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  fullWidth
                  label='Until'
                  value={until}
                  onChange={setUntil}
                  animateYearScrolling
                  inputVariant='outlined'
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpenDialog('addPromoDialog', false)}>Cancel</Button>
        <Button onClick={handleAddPromoDates}>SAVE</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPromoDialog;
