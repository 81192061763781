import React, { useState } from 'react';
import { suppliersImages } from '../../../../../data';
import { quotationURL } from '../../../../../../helpers';
import { getProtections } from './utils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  Grid,
  Select,
  MenuItem,
  ListItem,
  TextField,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  Button,
  CircularProgress
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import axios from 'axios';
import { useEffect } from 'react';
import TotalTable from './Components/TotalTable';
import { roundUpToTwoDigits, getNumberOfDays } from 'helpers/utils';
const today = new Date();
const ConvertNumberToTwoDigitString = n => (n > 9 ? '' + n : '0' + n);

const Quote = ({ language, firstName, lastName, phone, email, age, lead, ...rest }) => {
  const selectedCar =
    lead !== undefined &&
    lead.comment[lead.comment.length - 1].selectedCar !== undefined &&
    lead.comment[lead.comment.length - 1].selectedCar;
  const [quoteSent, setQuoteSent] = useState(false);
  const [cars, setCars] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [pickUpAndDropOffDates, setPickUpAndDropOffDates] = useState({
    pickUpDateAndTime: today,
    dropOffDateAndTime: new Date(new Date().setDate(today.getDate() + 3))
  });
  const [pickUpAndDropOffLocations, setPickUpAndDropOffLocations] = useState({
    pickUpLocation: '',
    dropOffLocation: ''
  });
  const [quote, setQuote] = useState({
    protections: {
      CDW: { chosen: true, price: 0 },
      TP: { chosen: true, price: 0 },
      three_PLC: { chosen: true, price: 0 },
      superCDW: { chosen: false, price: 0 },
      superTP: { chosen: false, price: 0 }
    },
    driverDetails: {
      title: '',
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phone: phone || '',
      age: age || ''
    },
    oneWayFee: 0,
    userExtras: {
      underageDriver: { count: '', price: 0 },
      simCard: { count: '', price: 0 },
      additionalDriverPrice: { count: '', price: 0 },
      childSeatPrice: { count: '', price: 0 },
      childBoosterPrice: { count: '', price: 0 },
      CSB: { count: '', price: 0 },
      airportFee: { count: 0, price: 0 }
    },
    excess: '',
    quoteDate: new Date(),
    // pickUpLocation: "",
    // dropOffLocation: "",
    // pickUpDateAndTime: today,
    // dropOffDateAndTime: new Date(new Date().setDate(today.getDate() + 3)),
    numberOfDays: '',
    carBasePrice: '',
    language,
    supplier: { name: '', value: '' },
    vehicle_type: { name: '', image: '' },
    typeOfGearBox: ''
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [guaranteeDeposit, setGuaranteeDeposit] = useState('');

  const getTotalPrice = () => {
    const { userExtras, numberOfDays } = quote;
    let price = roundUpToTwoDigits(quote.carBasePrice * quote.numberOfDays + quote.oneWayFee);
    for (const protection in quote.protections) {
      if (quote.protections[protection].chosen) {
        price += roundUpToTwoDigits(quote.protections[protection].price * numberOfDays);
      }
    }
    for (const extra in userExtras) {
      if (userExtras[extra].count > 0 && userExtras[extra].price > 0) {
        price += roundUpToTwoDigits(userExtras[extra].count * userExtras[extra].price * numberOfDays);
      }
    }
    setTotalPrice(price);
  };
  useEffect(() => {
    const { numberOfDays } = quote;
    const { pickUpLocation, dropOffLocation } = pickUpAndDropOffLocations;
    if (pickUpLocation && dropOffLocation) {
      if (language === 'en') {
        if (pickUpLocation.includes('Airport') || dropOffLocation.includes('Airport')) {
          setQuote({ ...quote, userExtras: { ...quote.userExtras, airportFee: { count: 1, price: 39 } } });
        }
        if (numberOfDays && numberOfDays < 3) {
          if (
            (pickUpLocation.includes('Eilat') && !dropOffLocation.includes('Eilat')) ||
            (!pickUpLocation.includes('Eilat') && dropOffLocation.includes('Eilat'))
          ) {
            setQuote({ ...quote, oneWayFee: 200 });
          }
        }
      } else {
        if (pickUpLocation.includes('Аэропорт') || dropOffLocation.includes('Аэропорт')) {
          setQuote({ ...quote, userExtras: { ...quote.userExtras, airportFee: { count: 1, price: 39 } } });
        }
        if (numberOfDays && numberOfDays < 3) {
          if (
            (pickUpLocation.includes('Эйлат') && !dropOffLocation.includes('Эйлат')) ||
            (!pickUpLocation.includes('Эйлат') && dropOffLocation.includes('Эйлат'))
          ) {
            setQuote({ ...quote, oneWayFee: 200 });
          }
        }
      }
    }
  }, [pickUpAndDropOffLocations, language, pickUpAndDropOffDates]);
  useEffect(() => {
    setGuaranteeDeposit(roundUpToTwoDigits(totalPrice + 200 + quote.excess));
  }, [totalPrice, quote.excess]);
  useEffect(() => {
    getTotalPrice();
  }, [quote.userExtras, quote.protections, quote.carBasePrice]);

  // useEffect(() => {
  //   console.log(quote)
  // }, [quote])

  useEffect(() => {
    if (quote.supplier.name !== '') {
      const updateSupplier = async () => {
        const url = `https://we4rent.herokuapp.com/api/prices/extras?supplier=Europcar`;
        let prices;
        try {
          prices = (await axios.get(url)).data;
        } catch (err) {
          console.log('ERROR', err);
        }
        if (prices !== undefined) {
          // const handleSupplierPrices = prices => {
          const userExtras = {};

          for (const extra in prices) {
            if (extra === 'cbsPrice') userExtras.childBoosterPrice = { count: 0, price: prices[extra] };
            else {
              userExtras[extra] = { count: 0, price: prices[extra] };
            }
          }
          userExtras.simCard = { count: 0, price: 10 };
          setQuote({ ...quote, userExtras });
        }

        // handleSupplierPrices(prices)
      };

      updateSupplier();
    }
  }, [quote.supplier.name]);

  const styles = {
    border: {
      marginBottom: '32px',
      border: '1px solid #C9C9C9',
      borderRadius: '4px'
    },
    carImage: {
      maxWidth: '100%',
      maxHeight: '165px'
    }
  };
  const [appeal, setAppeal] = React.useState({
    ru: 'Уважаемый',
    en: 'Dear'
  });
  const handleChange = value => {
    setAppeal({ ...appeal, ru: value });
  };
  const sendQuotation = () => {
    setIsSending(true);
    const url = quotationURL('savequotation');
    const { protections } = quote;
    let tempProtections = {
      CDW: 0,
      TP: 0,
      three_PLC: 0,
      superCDW: 0,
      superTP: 0
    };
    for (const protection in protections) {
      if (protections[protection].chosen) {
        tempProtections[protection] = protections[protection].price;
      }
    }
    let quoteToSend = { ...quote, ...pickUpAndDropOffLocations, ...pickUpAndDropOffDates };
    quoteToSend.protections = tempProtections;
    axios({
      method: 'POST',
      url,
      data: {
        quoteToSend
      }
    })
      .then(res => {
        if (res.data.saved) {
          setIsSending(false);
          setQuoteSent(true);
        }
      })
      .catch(err => {
        setIsSending(false);
        setQuoteSent(false);
        console.log(err);
      });
  };
  const Extra = ({ extraName, name, price }) => {
    const arr = [...new Array(10)].map((item, idx) => (item = idx));
    return (
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth style={{ minWidth: '170px' }}>
          <InputLabel>{extraName}</InputLabel>

          <Select
            // variant='outlined'
            name={name}
            fullWidth
            value={quote.userExtras[name].count}
            onChange={handleExtraChange}
          >
            {arr.map((item, idx) => (
              <MenuItem key={idx} value={idx}>
                {idx}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  useEffect(() => {
    const { pickUpDateAndTime, dropOffDateAndTime } = pickUpAndDropOffDates;
    const pickUpHour =
      ConvertNumberToTwoDigitString(pickUpDateAndTime.getHours()) +
      ':' +
      ConvertNumberToTwoDigitString(pickUpDateAndTime.getMinutes());
    const dropOffHour =
      ConvertNumberToTwoDigitString(dropOffDateAndTime.getHours()) +
      ':' +
      ConvertNumberToTwoDigitString(dropOffDateAndTime.getMinutes());
    if (quote.driverDetails.age > 18) {
      const url =
        `https://we4rent.herokuapp.com/api/search?age=${quote.driverDetails.age}` +
        `&pickUpYear=${pickUpDateAndTime.getFullYear()}&pickUpMonth=${pickUpDateAndTime.getMonth()}&pickUpDay=${pickUpDateAndTime.getDate()}` +
        `&pickUpHour=${pickUpHour}&dropOffYear=${dropOffDateAndTime.getFullYear()}&dropOffMonth=${dropOffDateAndTime.getMonth()}&dropOffDay=${dropOffDateAndTime.getDate()}&dropOffHour=${dropOffHour}`;
      axios.get(url).then(docs => {
        setCars(docs.data.cars);
        /**
         * TODO
         */
        //     if (selectedCar !== undefined){
        //   handleCarChange(selectedCar)
        // }
      });
    }
  }, [quote.driverDetails.age, pickUpAndDropOffDates]);

  const handleSupplierChange = value => {
    const supplier = suppliersImages.find(supplier => supplier.value === value);
    setQuote({ ...quote, supplier });
  };
  const handleNameChange = e => {
    const { name, value } = e.target;
    const { driverDetails } = quote;
    driverDetails[name] = value;
    setQuote({ ...quote, driverDetails });
  };
  const handleProtectionChange = e => {
    const protectionName = e.target.name;
    const chosen = e.target.checked;
    const price = quote.protections[protectionName].price;
    setQuote({ ...quote, protections: { ...quote.protections, [protectionName]: { chosen, price } } });
  };
  const handleExtraChange = e => {
    const { name, value } = e.target;
    const price = quote.userExtras[name].price;
    setQuote({ ...quote, userExtras: { ...quote.userExtras, [name]: { count: value, price } } });
  };

  const handleCarChange = value => {
    const car = cars.find(car => car.vehicle_type === value);
    const vehicle_type = { name: car.vehicle_type, image: car.image };
    const { protection_coverage } = car.prices;
    if (car)
      setQuote({
        ...quote,
        vehicle_type,
        typeOfGearBox: car.typeOfGearBox,
        carBasePrice: car.basePrice.price,
        protections: {
          CDW: { chosen: true, price: protection_coverage.CDW },
          TP: { chosen: true, price: protection_coverage.TP },
          three_PLC: { chosen: true, price: 0 },
          superCDW: { chosen: false, price: protection_coverage.super_CDW },
          superTP: { chosen: false, price: protection_coverage.super_TP }
        },
        excess: protection_coverage.excess
      });
  };
  return (
    <Grid container spacing={2}>
      {/**Left column */}
      <Grid item lg={6} sm={6}>
        <Typography variant='h6' style={{ marginBottom: '8px', textAlign: 'center' }}>
          {language === 'en' ? 'Driver Details' : 'Данные водителя'}
        </Typography>

        <Grid container alignItems='flex-end' spacing={2} style={{ ...styles.border, width: '100%' }}>
          {/**Driver details */}

          <Grid item lg={3} md={3} xs={12}>
            {language === 'ru' ? (
              <Select fullWidth value={appeal[language]} onChange={handleChange}>
                <MenuItem value='Уважаемый'>Уважаемый</MenuItem>
                <MenuItem value='Уважаемая'>Уважаемая</MenuItem>
              </Select>
            ) : (
              <Typography>Dear</Typography>
            )}
          </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <TextField
              autoComplete='none'
              fullWidth
              label={language === 'en' ? 'First name' : 'Имя'}
              name='firstName'
              value={quote.driverDetails.firstName}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <TextField
              fullWidth
              autoComplete='none'
              name='lastName'
              label={language === 'en' ? 'Last name' : 'Фамилия'}
              value={quote.driverDetails.lastName}
              onChange={handleNameChange}
            />
          </Grid>

          <Grid item lg={3} md={3} xs={12}>
            <TextField
              fullWidth
              value={quote.driverDetails.age}
              label={language === 'en' ? 'Age' : 'Возраст'}
              type='number'
              onChange={e =>
                setQuote({
                  ...quote,
                  driverDetails: {
                    ...quote.driverDetails,
                    age: e.target.value
                  }
                })
              }
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              autoComplete='none'
              fullWidth
              name='email'
              label='Email'
              value={quote.driverDetails.email}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              autoComplete='none'
              fullWidth
              name='phone'
              label={language === 'en' ? 'Phone' : 'Телефон'}
              value={quote.driverDetails.phone}
              onChange={e =>
                setQuote({ ...quote, driverDetails: { ...quote.driverDetails, phone: e.target.value } })
              }
            />
          </Grid>
        </Grid>
        {/**Pick up and drop off */}
        <Typography variant='h6' style={{ marginBottom: '8px', textAlign: 'center' }}>
          {language === 'en' ? 'Pick up & drop off' : 'Получение и возврат'}
        </Typography>

        <Grid container spacing={1} style={{ ...styles.border, width: '100%' }}>
          <Grid item lg={6} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{language === 'en' ? 'Pick up location' : 'Место получения'}</InputLabel>

              <Select
                style={{ marginRight: '8px' }}
                fullWidth
                value={pickUpAndDropOffLocations.pickUpLocation}
                onChange={e =>
                  setPickUpAndDropOffLocations({
                    ...pickUpAndDropOffLocations,
                    pickUpLocation: e.target.value
                  })
                }
              >
                {/* {europcarBranches[language].map(branch => (
                  <ListItem key={branch} value={branch}>
                    {branch}
                  </ListItem>
                ))} */}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                fullWidth
                autoOk
                ampm={false}
                disablePast
                value={pickUpAndDropOffDates.pickUpDateAndTime}
                onChange={date => {
                  setQuote({
                    ...quote,
                    numberOfDays: getNumberOfDays(date, pickUpAndDropOffDates.dropOffDateAndTime)
                  });
                  setPickUpAndDropOffDates({ ...pickUpAndDropOffDates, pickUpDateAndTime: date });
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={6} sm={6} xl={4}>
            <FormControl fullWidth>
              <InputLabel>{language === 'en' ? 'Drop off location' : 'Место возврата'}</InputLabel>

              <Select
                fullWidth
                value={pickUpAndDropOffLocations.dropOffLocation}
                onChange={e =>
                  setPickUpAndDropOffLocations({
                    ...pickUpAndDropOffLocations,
                    dropOffLocation: e.target.value
                  })
                }
              >
                {/* {europcarBranches[language].map(branch => (
                  <ListItem key={branch} value={branch}>
                    {branch}
                  </ListItem>
                ))} */}
              </Select>
            </FormControl>
            <LocalizationProvider utils={AdapterDateFns}>
              <DateTimePicker
                fullWidth
                autoOk
                ampm={false}
                disablePast
                value={pickUpAndDropOffDates.dropOffDateAndTime}
                onChange={date => {
                  setQuote({
                    ...quote,
                    dropOffDateAndTime: date,
                    numberOfDays: getNumberOfDays(pickUpAndDropOffDates.pickUpDateAndTime, date)
                  });
                  setPickUpAndDropOffDates({ ...pickUpAndDropOffDates, dropOffDateAndTime: date });
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        {/**Car section */}
        <Typography variant='h6' style={{ marginBottom: '8px', textAlign: 'center' }}>
          {language === 'en' ? 'Car details' : 'Машина'}
        </Typography>

        <Grid container style={styles.border}>
          <Grid item lg={6} sm={6} xl={6} style={{ padding: '8px', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControl>
                <InputLabel>{language === 'en' ? 'Choose a car' : 'Выберете машину'}</InputLabel>

                <Select value={quote.vehicle_type.name} onChange={e => handleCarChange(e.target.value)}>
                  {cars.map(car => (
                    <ListItem key={car.vehicle_type} value={car.vehicle_type}>
                      {car.vehicle_type}
                    </ListItem>
                  ))}
                </Select>
              </FormControl>

              {quote.vehicle_type.image !== '' && (
                <img style={styles.carImage} src={quote.vehicle_type.image} alt={quote.vehicle_type.name} />
              )}

              <FormControl>
                <InputLabel>{language === 'en' ? 'Choose a supplier' : 'Выберете прокатчика'}</InputLabel>
                <Select value={quote.supplier.value} onChange={e => handleSupplierChange(e.target.value)}>
                  {suppliersImages.map(supplier => (
                    <ListItem key={supplier.name} value={supplier.value}>
                      {supplier.name}
                    </ListItem>
                  ))}
                </Select>
              </FormControl>

              {quote.supplier.value && (
                <img
                  style={styles.carImage}
                  src={`http://www.we4rent.com/public/suppliersimages/${quote.supplier.value}`}
                  alt={suppliersImages[0].value}
                />
              )}
              {/* <Select fullwidth value={"АКПП"} onChange={handleChange}>
                  <ListItem value="АКПП">АКПП</ListItem>
                  <ListItem value="МКПП">МКПП</ListItem>
                </Select> */}

              <FormControl>
                <InputLabel style={{ marginTop: '-14px' }}>
                  {language === 'en' ? 'Gear type' : 'Тип коробки передач'}
                </InputLabel>
                <TextField
                  InputProps={{
                    readOnly: true
                  }}
                  value={quote.typeOfGearBox}
                />
                {/* <Select

                  value={quote.typeOfGearBox}
                  onChange={e => setQuote({ ...quote, typeOfGearBox: e.target.value })}
                >
                  <ListItem value="Automatic">{language === 'en' ? 'Automatic' : 'АКПП'}</ListItem>
                  <ListItem value="Manual">{language === 'en' ? 'Manual' : 'МКПП'}</ListItem>
                </Select> */}
              </FormControl>
            </div>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} style={{ padding: '8px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                fullWidth
                label={language === 'ru' ? 'Сумма франшизы' : 'Excess charge'}
                type='number'
                value={quote.excess}
                onChange={e => setQuote({ ...quote, excess: e.target.value })}
              />
              <TextField
                fullWidth
                label={language === 'ru' ? 'Сумма депозита' : 'Guarantee deposit'}
                value={guaranteeDeposit}
                type='number'
              />
            </div>
          </Grid>
        </Grid>
        <Divider orientation='vertical' flexItem />
      </Grid>
      {/* <Divider orientation="vertical" flexItem /> */}
      <Grid item lg={6} sm={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Typography variant='h6' style={{ textAlign: 'center' }}>
              {language === 'en' ? 'Protections' : 'Страховки'}
            </Typography>
            <FormGroup style={{ ...styles.border, padding: '8px' }}>
              <FormControlLabel
                control={<Checkbox checked={true} onChange={handleChange} name='' />}
                label={
                  language === 'ru' ? 'CDW - базовая страховка от ущерба' : 'Collision Damage Waiver (CDW)'
                }
              />
              <FormControlLabel
                control={<Checkbox checked={true} onChange={handleChange} name='' />}
                label={language === 'ru' ? 'TP - Базовая страховка от угона' : 'Theft Waiver protection (TP)'}
              />
              <FormControlLabel
                control={<Checkbox checked={true} onChange={handleChange} name='' />}
                label={language === 'ru' ? 'LIA - страховка' : 'Liability Coverage (LIA)'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={quote.protections.superCDW.chosen}
                    onChange={handleProtectionChange}
                    name='superCDW'
                  />
                }
                label='Super CDW'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={quote.protections.superTP.chosen}
                    onChange={handleProtectionChange}
                    name='superTP'
                  />
                }
                label='Super TP '
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant='h6' style={{ textAlign: 'center' }}>
              {language === 'en' ? 'Fees' : 'Дополнительные сборы'}
            </Typography>
            <FormGroup style={{ ...styles.border, padding: '8px' }}>
              <FormControlLabel
                control={<Checkbox checked={quote.numberOfDays > 2} onChange={handleChange} name='' />}
                label={language === 'ru' ? 'Километраж без ограничения' : 'Unlimited mileage'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={quote.userExtras.airportFee.count > 0 ? true : false}
                    // onChange={e =>
                    //   setQuote({ ...quote, userExtras: { ...quote.userExtras, airportFee: { count: e.target.checked ? 1 : 0, price: e.target.checked ? 39 : 0 } } })}
                  />
                }
                label={language === 'ru' ? 'Налог аэропорта' : 'Airport fee'}
              />
              <FormControlLabel
                control={<Checkbox checked={false} onChange={handleChange} name='' />}
                label='Налог на лицензию транспортного средства'
              />
              <FormControlLabel
                control={<Checkbox checked={quote.oneWayFee > 0} onChange={handleChange} name='' />}
                label={language === 'ru' ? 'Поездка в одну сторону' : 'One way fee'}
              />
              <FormControlLabel
                control={<Checkbox checked={quote.driverDetails.age < 23} onChange={handleChange} name='' />}
                label={language === 'ru' ? 'Молодой водитель' : 'Underage driver'}
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid>
          <Typography variant='h6' style={{ textAlign: 'center' }}>
            {language === 'en' ? 'Extras' : 'Дополнительные опции'}
          </Typography>
          <Grid container spacing={1} style={{ ...styles.border, padding: '8px', marginTop: '4px' }}>
            <Extra
              extraName={language === 'en' ? 'Additional driver' : 'Дополнительный водитель'}
              name='additionalDriverPrice'
            />
            <Extra
              extraName={language === 'en' ? 'Child Seat for Infant' : 'Детское автокресло люлька'}
              name='childSeatPrice'
            />
            <Extra
              extraName={language === 'en' ? 'Child Seat for Baby' : 'Детское автокресло для детей'}
              name='CSB'
            />
            <Extra
              extraName={language === 'en' ? 'Child Booster Seat' : 'Детский бустер CBS'}
              name='childBoosterPrice'
            />
            <Extra extraName={language === 'en' ? 'Sim card' : 'Сим карта'} name='simCard' />
          </Grid>
          <Grid container>
            <Grid item style={styles.border}>
              <TotalTable quote={quote} language={language} totalPrice={totalPrice} />
            </Grid>
          </Grid>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button onClick={sendQuotation} disabled={quoteSent} variant='contained' >{language === 'en' ? 'Send quotation' : 'Отправить предложение'}</Button> */}
            <Grid item style={{ position: 'relative' }}>
              <Button
                disabled={quoteSent || isSending}
                variant='contained'
                // color='primary'
                // style={{ margin: '16px 0px' }}
                onClick={sendQuotation}
              >
                {quoteSent
                  ? language === 'en'
                    ? 'Quotation sent'
                    : 'Предложение Отправлено'
                  : language === 'en'
                  ? 'Send quotation'
                  : 'Отправить предложение'}
              </Button>
              {isSending && (
                <CircularProgress
                  size={24}
                  style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }}
                />
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Quote;
